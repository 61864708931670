import React from 'react';

import { Resume } from '../Components/Resume';

const ResumePage = (props) => (
    <section>        
        <Resume />                      
    </section>
);

export default ResumePage;